<template>
  <div class="body-content">
    <banner v-if="bannerList && bannerList.length > 0" :homeBanner="bannerList"></banner>
    <!-- 搜索框 -->
    <!-- <long-search @search="search" style="margin: 40px 0;"></long-search> -->
    <div class="bg-rgba-w-1" style="padding: 0 20px 20px 20px;">
      <section-title title="通知公告" :showMore="true" @moreClick="goNotice" :hideDivider="true"></section-title>
      <div style="padding: 0 20px;">
        <point-title v-for="item in announcementList" :key="item.id" :title="item.title"
          :time="item.time ? item.time.substring(0, 10) : '--'" :hidePoint="true"
          @goDetail="goNotice(item.id)"></point-title>
      </div>
    </div>
    <section-title title="环境试验" :showMore="true" @moreClick="moreClick('hjsy')"></section-title>
    <div class="shiyan-wrapper">
      <div class="item-wrapper">
        <img class="image" :src="environmentList[0]['pictureUrl']" />
        <div class="h1 title">
          {{ environmentList[0]['title'] }}
          <br />环境试验中心
        </div>
        <div class="button h3" @click="moreClick('hjsy', environmentList[0]['id'])">查看详情</div>
      </div>
      <div class="divider-h"></div>
      <div class="item-wrapper">
        <img class="image" :src="environmentList[1]['pictureUrl']" />
        <div class="h1 title">
          {{ environmentList[1]['title'] }}
          <br />环境试验中心
        </div>
        <div class="button h3" @click="moreClick('hjsy', environmentList[1]['id'])">查看详情</div>
      </div>
    </div>
    <section-title title="并行/仿真计算" :showMore="true" @moreClick="moreClick('js')"></section-title>
    <div class="shiyan-wrapper">
      <div class="item-wrapper">
        <img class="image" :src="calculationList[0]['pictureUrl']" />
        <div class="button h3" @click="moreClick('js', calculationList[0]['id'])">超算中心算力资源平台</div>
      </div>
      <div class="divider-h"></div>
      <div class="item-wrapper">
        <img class="image" :src="calculationList[1]['pictureUrl']" />
        <div class="button h3" @click="moreClick('js', calculationList[1]['id'])">超算中心运营服务平台</div>
      </div>
    </div>
    <section-title title="算力服务" :showMore="true" @moreClick="moreClick('slfw')"></section-title>
    <div style="height: 300px;position: relative;">
      <img style="width: 100%;height: 100%;position: absolute;" src="../../static/images/suanlifuwu.png" />
      <div class="digital-big-button" @click="moreClick('slfw')">算力服务申请</div>
    </div>
    <section-title title="项目申报" :showMore="false"></section-title>
    <div style="height: 190px;position: relative;">
      <img style="width: 100%;height: 100%;position: absolute;" src="../../static/images/xiangmushenbao.png" />
      <div class="digital-big-button" style="right: 100px;left: unset;top: 74px;" @click="moreClick('shenbao')">项目申报平台
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '../../components/banner.vue';
import longSearch from '@/components/long-search.vue'
import pointTitle from '@/components/point-article-title.vue'
import sectionTitle from '@/views/policyHall/components/section-title.vue'
export default {
  name: 'digi-index',
  components: {
    Banner,
    longSearch,
    pointTitle,
    sectionTitle,
  },
  data() {
    return {
      pageTopPosition: 0,
      bannerList: [
      ],
      announcementList: [],
      environmentList: [
        {
          'pictureUrl': ''
        },
        {
          'pictureUrl': ''
        }
      ],
      calculationList: [
        {
          'pictureUrl': ''
        },
        {
          'pictureUrl': ''
        }
      ],

    }
  },
  beforeRouteLeave(to, from, next) {
    this.pageTopPosition = document.getElementById('app').scrollTop
    next()
  },
  activated() {
    document.getElementById('app').scrollTop = this.pageTopPosition
  },
  created() {
    // 调用工具方法示例
    console.log(this.$util.testName());
  },
  mounted() {
    this.getIndexInfo()
  },
  methods: {
    getIndexInfo() {
      this.$api.digitalServices.index().then((res) => {
        //console.log('res = ', res)
        const data = res.data.data
        if (data) {
          this.bannerList = data.bannerList
          this.announcementList = data.announcementList
          this.environmentList = data.environmentList
          this.calculationList = data.calculationList
        }
      })
    },
    search(v) {
      this.$router.push('/digitalServices/page-search?keywords=' + v)
    },
    moreClick(page, id) {
      console.log('page , id - ', page, id)
      if (page == 'hjsy') {
        if (id) {
          this.$router.push({ path: '/digitalServices/page-hjsy/articledetail', query: { id: id, 'detailType': 'ds-environment' } })
        }
        else {
          this.$router.push('/digitalServices/page-hjsy')
        }
      } else if (page == 'slfw') {
        this.$router.push('/digitalServices/page-slfw')
      } else if (page == 'js') {
        if (id) {
          this.$router.push({ path: '/digitalServices/page-js/articledetail', query: { id: id, 'detailType': 'ds-calculation' } })
        }
        else {
          this.$router.push('/digitalServices/page-js')
        }
      } else if (page == 'shenbao') {
        //连接到外部连接
        window.open('http://kjj.my.gov.cn/', '_blank');
      }
    },
    goNotice(id) {
      if (id) {
        this.$router.push({ path: '/digitalServices/page-notice/articledetail', query: { id: id, 'detailType': 'ds-notice' } })
      }
      else {
        this.$router.push('/digitalServices/page-notice')
      }
    },
  }
}
</script>

<style scoped lang="less">
.shiyan-wrapper {
  display: flex;
  height: 380px;
  width: 100%;

  .item-wrapper {
    flex: 1;
    height: 100%;
    min-width: 100px;
    flex-shrink: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .image {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
    }

    .title {
      text-align: center;
      margin: 20px;
      z-index: 1;
      font-size: 28px !important;
    }

    .button {
      min-width: 160px;
      height: 54px;
      text-align: center;
      line-height: 54px;
      cursor: pointer;
      z-index: 1;
      background-color: #f39800;
      border-radius: 17px;
      font-size: 20px;
      padding: 0 20px;
    }
  }

  .divider-h {
    width: 20px;
    flex-shrink: 0;
  }
}

.digital-big-button {
  width: 170px;
  padding: 0 20px;
  height: 54px;
  text-align: center;
  line-height: 54px;
  cursor: pointer;
  z-index: 1;
  background-color: #f39800;
  border-radius: 22px;
  position: absolute;
  left: 100px;
  top: 126px;
  display: inline-block;
  font-size: 20px;
}
</style>
